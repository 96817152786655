<template lang="pug">
.agreementOrders
  .planlist
    el-row(style='margin-bottom:1rem')
      el-col(:span='24', style='text-align:left')
        //- el-input(style='width:200px;', v-model='paginationInfo.condition.search_key', placeholder='请输入手机号/商户单号', clearable)
        //- el-select(v-model='paginationInfo.condition.trade_status', placeholder='请选择订单状态', @change='queryList', filterable, clearable)
        //-   el-option(label='全部', value)
        //-   el-option(label='预创建', value='PRE_CREATE')
        //-   el-option(label='支付成功', value='TRADE_SUCCESS')
        //-   el-option(label='交易关闭', value='TRADE_CLOSED')
        //-   el-option(label='交易完结', value='TRADE_FINISHED')
        //-   el-option(label='交易创建', value='WAIT_BUYER_PAY')
        //- el-select(v-model='paginationInfo.condition.order_status', placeholder='请选择签约状态', @change='queryList', filterable, clearable)
        //-   el-option(label='全部', value)
        //-   el-option(label='初始化', value='INIT')
        //-   el-option(label='已签约', value='AUTHORIZED')
        //-   el-option(label='完结', value='FINISH')
        //-   el-option(label='已关闭', value='CLOSED')
        //- el-select(v-model='paginationInfo.condition.biz_type', placeholder='请选择订单类型', @change='queryList', filterable, clearable)
        //-   el-option(label='全部订单类型', value)
        //-   el-option(v-for='item in orderDoctData', :key='item.dictdata_value', :label='item.dictdata_name', :value='item.dictdata_value')
        //- el-select(v-model='paginationInfo.condition.channel_code', placeholder='请选择渠道', @change='queryList', filterable, clearable)
        //-   el-option(label='全部渠道', value)
        //-   el-option(v-for='item in dictData', :key='item.dictdata_value', :label='item.dictdata_name', :value='item.dictdata_value')
        //- el-select(v-model='paginationInfo.condition.account_channel', placeholder='请选择办理账号', @change='queryList', filterable, clearable)
        //-   el-option(label='全部办理账号', value)
        //-   el-option(v-for='item in accountChannelList', :key='item.dictdata_value', :label='item.dictdata_name', :value='item.dictdata_value')
        //- el-button(@click='queryList') 查询
    el-table(:data='tableData', style='width: 100%')
      el-table-column(prop='external_agreement_no', label='签约订单号', align='center', width='150')
      el-table-column(prop='mobile', label='手机号', align='center', width='150')
      el-table-column(label='创建时间', align='center', width='300')
        template(slot-scope='scope') {{ $moment(scope.row.create_date).format('YYYY-MM-DD HH:mm:ss')}}
      el-table-column(prop='member_type', label='会员业务编码', align='center')
      el-table-column(prop='period_rule_params.execute_time', label='首次执行时间', align='center')
      el-table-column(label='订单类型', align='center')
        template(slot-scope='scope')
            span(v-if="scope.row.biz_type == 'U_SHARE_MEMBER'") 优享会员
            span(v-else-if="scope.row.biz_type == 'MEITUAN_HONGBAO'") 美团红包
            span(v-else-if="scope.row.biz_type == 'EASY_ORDER'") 商品订单
            span(v-else-if="scope.row.biz_type == 'EQUITY_MEMBER'") 权益会员
            span(v-else) 无
      el-table-column(label='支付价格', align='center')
        template(slot-scope='scope') {{ scope.row.period_rule_params.single_amount }}元/{{ scope.row.period_rule_params.period_type | periodLabel }}
      el-table-column(label='渠道', align='center')
        template(slot-scope='scope') {{ getDictName(scope.row.channel_code) }}
      el-table-column(label='办理账号', align='center')
        template(slot-scope='scope') {{ getAccountChannelName(scope.row.account_channel) }}
      el-table-column(label='订单状态', align='center')
        template(slot-scope='scope')
            span(v-if="scope.row.status == 'NORMAL'") 已签约
            span(v-else-if="scope.row.status == 'TEMP'") 暂存
            span(v-else-if="scope.row.status == 'STOP'") 暂停
      el-table-column(label='操作', align='center')
        template(slot-scope='scope')
            el-popover(placement="left", width="800", trigger="click" @show="getAgreementPayOrders(scope.row)")
                el-table(:data="currentShowPayOrders", v-loading="isPayOrderLoading")
                    el-table-column(prop='out_trade_no', label='订单号')
                    el-table-column(prop='create_date', label='支付发起时间')
                        template(slot-scope='scope') {{ $moment(scope.row.create_date).format('YYYY-MM-DD HH:mm:ss')}}
                    el-table-column(label='支付状态')
                        template(slot-scope='scope')
                            span(v-if="scope.row.trade_status == 'TRADE_SUCCESS'") 支付成功
                            span(v-else-if="scope.row.trade_status == 'PRE_CREATE'") 预创建
                            span(v-else-if="scope.row.trade_status == 'PRE_CREATE'") 交易关闭
                            span(v-else-if="scope.row.trade_status == 'TRADE_FINISHED'") 交易完结
                            span(v-else-if="scope.row.trade_status == 'WAIT_BUYER_PAY'") 交易创建
                    el-table-column(prop='external_agreement_no', label='签约订单号')
                el-button(slot="reference", type="warning") 支付记录
    el-pagination(
        @size-change='handleSizeChange',
        @current-change='handleCurrentChange',
        :current-page='paginationInfo.pageNo',
        :page-sizes='[10, 20, 50, 100]',
        :page-size='paginationInfo.pageSize',
        :total='paginationInfo.total',
        layout='total, sizes, prev, pager, next, jumper'
    )
</template>

<script>
export default {
    components: {},
    data() {
        return {
            orderDoctData: [],
            dictData: [],
            accountChannelList: [],
            create_date: [],
            paginationInfo: {
                pageSize: 10,
                total: 0,
                pageNo: 1,
                condition: {
                    out_trade_no: '',
                    account_channel: '',
                    create_date: '',
                    trade_status: '',
                    biz_type: '',
                    channel_code: ''
                }
            },
            tableData: [],
            formData: {
            },
            isPayOrderLoading: false,
            currentShowPayOrders: []
        }
    },
    computed: {},
    watch: {},
    created() {
        this.queryList();
        this.getDict();
        this.getAccountChannel();
        this.getOrderDoctData();
    },
    mounted() {
    },
    methods: {
        getAccountChannelName(str) {
            let name = "无";
            this.accountChannelList.forEach(item => {
                if (item.dictdata_value == str) {
                    name = item.dictdata_name;
                }
            });
            return name;
        },
        getDictName(str) {
            let name = "无";
            this.dictData.forEach(item => {
                if (item.dictdata_value == str) {
                    name = item.dictdata_name;
                }
            });
            return name;
        },
        getDict() {
            this.$service.CommonService
                .getDict({ dict: 'AdChannelInfos' })
                .then(res => {
                    if (res.data.code === 0) {
                        this.dictData = res.data.data.dict_data;
                    }
                })
        },
        getOrderDoctData() {
            this.$service.CommonService
                .getDict({ dict: 'PayBizTypes' })
                .then(res => {
                    if (res.data.code === 0) {
                        this.orderDoctData = res.data.data.dict_data;
                    }
                })
        },
        getAccountChannel() {
            this.$service.CommonService
                .getDict({ dict: 'AlipayAccountOptions' })
                .then(res => {
                    if (res.data.code === 0) {
                        this.accountChannelList = res.data.data.dict_data;
                    }
                })
        },
        queryList() {
            if (this.create_date.length >= 2 && this.create_date[0] && this.create_date[1]) {
                this.paginationInfo.condition.create_date = [this.create_date[0], this.create_date[1]];
            } else {
                this.paginationInfo.condition.create_date = null;
            }
            this.paginationInfo.pageNo = 1;
            this.getAgreementOrdersAL()
        },
        getAgreementPayOrders(agreement) {
            this.isPayOrderLoading = true
            this.$service.OnlineOrderService
                .getAgreementPayOrders(agreement._id)
                .then(res => {
                    if (res.data.code === 0) {
                        this.currentShowPayOrders = res.data.data
                    }
                })
                .finally(() => {
                    this.isPayOrderLoading = false
                })
        },
        handleSizeChange(val) {
            window.console.log(`每页 ${val} 条`);
            this.paginationInfo.pageSize = val;
            this.paginationInfo.pageNo = 1;
            this.getAgreementOrdersAL();
        },
        handleCurrentChange(val) {
            window.console.log(`当前页: ${val}`);
            this.paginationInfo.pageNo = val;
            this.getAgreementOrdersAL();
        },
        getAgreementOrdersAL() {
            this.$service.OnlineOrderService
                .getAgreementOrdersAL(this.paginationInfo)
                .then(res => {
                    if (res.data.code === 0) {
                        let dataInfo = res.data.data
                        this.paginationInfo.total = dataInfo.total;
                        this.tableData = dataInfo.agreements;
                    }
                })
        },
    },
    filters: {
        periodLabel(value) {
            let label = ''
            switch(value) {
                case 'MONTH':
                    label = '月'
                    break;
                case 'DAY':
                    label = '日'
                    break;
            }
            return label
        }
    }
}
</script>
<style lang='less' scoped>
.agreementOrders {
    ::v-deep .el-form-item__label {
        font-weight: bold;
    }
}
</style>